import React from 'react'
import { graphql, Link } from 'gatsby'
import { Container } from '@components/common'
import Icon from '@mrs-ui/Icon'
import Head from '@components/Head'

type WpSolution = {
  title: string
  id: string
  slug: string
  content: any
  date: string
  featuredImage: { node: { sourceUrl: string } }
  excerpt: string
}

type Solution = {
  wpSolution: WpSolution
}

const Solution = ({ data }) => {
  const { wpSolution }: Solution = data

  return (
    <>
      <Head
        title={wpSolution.title}
        description={wpSolution.excerpt}
        ogImage={wpSolution.featuredImage.node.sourceUrl}
      />
      <Container>
        <div className="flex flex-wrap py-24 blog-post">
          <article className="w-full flex flex-col items-center px-4 sm:px-0">
            <div className='max-w-4xl'>
                <header className="border-b border-shade100 pb-4 mb-12">
                <div className="mb-4 flex items-center text-small">
                    <Link to="/" className="hover:underline">
                    Home
                    </Link>
                    <Icon name="ChevronRight" className="h-4 w-4 mx-1 flex" />
                    <Link to="/solutions" className="hover:underline">
                    Solutions
                    </Link>
                </div>
                <h1 className="mb-2">{wpSolution.title}</h1>
                </header>
                <div
                dangerouslySetInnerHTML={{
                    __html: wpSolution.content,
                }}
                className="pb-12"
                />
            </div>
          </article>
        </div>
      </Container>
    </>
  )
}

export default Solution

export const data = graphql`
  query ($slug: String!) {
    wpSolution(slug: { eq: $slug }) {
      id
      slug
      title
      content
      excerpt
      date(formatString: "dddd, MMMM D YYYY")
      featuredImage {
        node {
          sourceUrl
        }
      }
    }
  }
`
